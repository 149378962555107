var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("RightToolbar", {
    staticClass: "mobile-side-bar",
    attrs: { absolute: "", mobileIsOpen: _vm.menuActive, mobile: "" },
    on: {
      "update:mobileIsOpen": function ($event) {
        _vm.menuActive = $event
      },
      "update:mobile-is-open": function ($event) {
        _vm.menuActive = $event
      },
    },
    scopedSlots: _vm._u(
      [
        _vm.game
          ? {
              key: "title",
              fn: function () {
                return [
                  _vm._v(
                    " " + _vm._s(_vm.game.externalName || _vm.game.name) + " "
                  ),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "additional-items",
          fn: function () {
            return [
              _vm.isTextChatOn || !_vm.isGamePage
                ? _c("div", { staticClass: "rtb-right-toolbar__list-item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "rtb-right-toolbar__list-item-cnt",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("openChat")
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "rtb-right-toolbar__list-item-icon" },
                          [
                            _c("SvgIcon", {
                              attrs: {
                                name: "chatcircles",
                                width: "24",
                                height: "24",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "rtb-right-toolbar__list-item-title" },
                          [
                            _vm._v(" Event Chat "),
                            _vm.unreadMessages
                              ? _c(
                                  "div",
                                  { staticClass: "mobile-side-bar__circle" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.unreadMessages) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "rtb-right-toolbar__list-item" }, [
                _c(
                  "div",
                  {
                    staticClass: "rtb-right-toolbar__list-item-cnt",
                    class: {
                      "rtb-right-toolbar__list-item-cnt--danger":
                        _vm.isUserMuted,
                    },
                    on: { click: _vm.toggleUserMute },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "rtb-right-toolbar__list-item-icon" },
                      [
                        _c("SvgIcon", {
                          attrs: {
                            name: _vm.isUserMuted ? "muted" : "microphone",
                            width: "20",
                            height: "20",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "rtb-right-toolbar__list-item-title" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.isUserMuted ? "Unmute" : "Mute") +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "rtb-right-toolbar__list-item" }, [
                _c(
                  "div",
                  {
                    staticClass: "rtb-right-toolbar__list-item-cnt",
                    on: { click: _vm.toggleCamera },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "rtb-right-toolbar__list-item-icon" },
                      [_c("v-icon", [_vm._v(" cached ")])],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "rtb-right-toolbar__list-item-title" },
                      [_vm._v("Switch Cameras")]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "rtb-right-toolbar__list-item" }, [
                _c(
                  "div",
                  {
                    staticClass: "rtb-right-toolbar__list-item-cnt",
                    on: { click: _vm.changeAV },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "rtb-right-toolbar__list-item-icon" },
                      [
                        _c("SvgIcon", {
                          staticClass: "rtb-right-toolbar__list-item-icon",
                          attrs: {
                            name: "audio-video-source",
                            width: "20",
                            height: "20",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "rtb-right-toolbar__list-item-title" },
                      [_vm._v("Change photo")]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "rtb-right-toolbar__list-item" }, [
                _c(
                  "div",
                  {
                    staticClass: "rtb-right-toolbar__list-item-cnt",
                    on: {
                      click: function ($event) {
                        _vm.$emit("onSwapPhone")
                        _vm.menuActive = false
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "rtb-right-toolbar__list-item-icon" },
                      [_c("i", { staticClass: "fas fa-exchange-alt mr-1" })]
                    ),
                    _c(
                      "div",
                      { staticClass: "rtb-right-toolbar__list-item-title" },
                      [_vm._v("Swap Phones")]
                    ),
                  ]
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "bottom",
          fn: function () {
            return [
              _vm.everyoneCanHearEachOther
                ? _c(
                    "div",
                    {
                      staticClass:
                        "rtb-right-toolbar__info rtb-right-toolbar__info--visible",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "rtb-right-toolbar__info-title" },
                        [_vm._v("Who can hear you:")]
                      ),
                      _c(
                        "div",
                        { staticClass: "rtb-right-toolbar__info-text" },
                        [
                          _c("span", [_vm._v("All participant talk is on,")]),
                          _vm._v(" everyone can hear you "),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "rtb-right-toolbar__list-item mt-auto" },
                [
                  _vm.hasPreGame && _vm.isGamePage
                    ? _c(
                        "div",
                        {
                          staticClass: "rtb-right-toolbar__list-item-cnt",
                          on: {
                            click: function ($event) {
                              return _vm.$navigator.navigateTo(
                                "/lobby/" + _vm.clientID
                              )
                            },
                          },
                        },
                        [
                          _c("SvgIcon", {
                            staticClass: "rtb-right-toolbar__list-item-icon",
                            attrs: {
                              name: "sign-out",
                              width: "20",
                              height: "20",
                              color: "danger",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "rtb-right-toolbar__list-item-title",
                            },
                            [_vm._v("Exit to Lobby")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "rtb-right-toolbar__list-item-cnt",
                          on: {
                            click: function ($event) {
                              return _vm.$navigator.navigateTo("/logout")
                            },
                          },
                        },
                        [
                          _c("SvgIcon", {
                            staticClass: "rtb-right-toolbar__list-item-icon",
                            attrs: {
                              name: "signout_24px",
                              width: "20",
                              height: "20",
                              fill: "#FF4060",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "rtb-right-toolbar__list-item-title",
                            },
                            [_vm._v("Sign out")]
                          ),
                        ],
                        1
                      ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }