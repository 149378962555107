<template>
  <RightToolbar
    absolute
    :mobileIsOpen.sync="menuActive"
    mobile
    class="mobile-side-bar"
  >
    <template #title v-if="game">
      {{ game.externalName || game.name }}
    </template>

    <template #additional-items>
      <div
        class="rtb-right-toolbar__list-item"
        v-if="isTextChatOn || !isGamePage"
      >
        <div
          class="rtb-right-toolbar__list-item-cnt"
          @click="$emit('openChat')"
        >
          <div class="rtb-right-toolbar__list-item-icon">
            <SvgIcon name="chatcircles" width="24" height="24" />
          </div>
          <div class="rtb-right-toolbar__list-item-title">
            Event Chat

            <div class="mobile-side-bar__circle" v-if="unreadMessages">
              {{ unreadMessages }}
            </div>
          </div>
        </div>
      </div>

      <div class="rtb-right-toolbar__list-item">
        <div
          class="rtb-right-toolbar__list-item-cnt"
          :class="{
            'rtb-right-toolbar__list-item-cnt--danger': isUserMuted
          }"
          @click="toggleUserMute"
        >
          <div class="rtb-right-toolbar__list-item-icon">
            <SvgIcon
              :name="isUserMuted ? 'muted' : 'microphone'"
              width="20"
              height="20"
            />
          </div>
          <div class="rtb-right-toolbar__list-item-title">
            {{ isUserMuted ? "Unmute" : "Mute" }}
          </div>
        </div>
      </div>

      <div class="rtb-right-toolbar__list-item">
        <div class="rtb-right-toolbar__list-item-cnt" @click="toggleCamera">
          <div class="rtb-right-toolbar__list-item-icon">
            <v-icon> cached </v-icon>
          </div>
          <div class="rtb-right-toolbar__list-item-title">Switch Cameras</div>
        </div>
      </div>

      <div class="rtb-right-toolbar__list-item">
        <div class="rtb-right-toolbar__list-item-cnt" @click="changeAV">
          <div class="rtb-right-toolbar__list-item-icon">
            <SvgIcon
              name="audio-video-source"
              width="20"
              height="20"
              class="rtb-right-toolbar__list-item-icon"
            />
          </div>
          <div class="rtb-right-toolbar__list-item-title">Change photo</div>
        </div>
      </div>

      <div class="rtb-right-toolbar__list-item">
        <div
          class="rtb-right-toolbar__list-item-cnt"
          @click="
            $emit('onSwapPhone')
            menuActive = false
          "
        >
          <div class="rtb-right-toolbar__list-item-icon">
            <i class="fas fa-exchange-alt mr-1" />
          </div>
          <div class="rtb-right-toolbar__list-item-title">Swap Phones</div>
        </div>
      </div>
    </template>

    <template #bottom>
      <div
        v-if="everyoneCanHearEachOther"
        class="rtb-right-toolbar__info rtb-right-toolbar__info--visible"
      >
        <div class="rtb-right-toolbar__info-title">Who can hear you:</div>
        <div class="rtb-right-toolbar__info-text">
          <span>All participant talk is on,</span> everyone can hear you
        </div>
      </div>

      <div class="rtb-right-toolbar__list-item mt-auto">
        <div
          v-if="hasPreGame && isGamePage"
          class="rtb-right-toolbar__list-item-cnt"
          @click="$navigator.navigateTo(`/lobby/${clientID}`)"
        >
          <SvgIcon
            name="sign-out"
            width="20"
            height="20"
            color="danger"
            class="rtb-right-toolbar__list-item-icon"
          />
          <div class="rtb-right-toolbar__list-item-title">Exit to Lobby</div>
        </div>

        <div
          v-else
          class="rtb-right-toolbar__list-item-cnt"
          @click="$navigator.navigateTo('/logout')"
        >
          <SvgIcon
            name="signout_24px"
            width="20"
            height="20"
            class="rtb-right-toolbar__list-item-icon"
            fill="#FF4060"
          />
          <div class="rtb-right-toolbar__list-item-title">Sign out</div>
        </div>
      </div>
    </template>
  </RightToolbar>
</template>

<script>
import { mapGetters } from "vuex"

import UserSoundService from "@/services/user-sound.service"
import ChatService from "@/components/Chat/index.js"

import { CHAT_CONTEXTS } from "@/components/Chat/constants"

export default {
  name: "MobileSideBar",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: Boolean,

      default: false
    }
  },
  setup() {},
  data() {
    return { unreadMessages: null }
  },
  computed: {
    ...mapGetters([
      "game",
      "isTextChatOn",
      "everyoneCanHearEachOther",
      "teamID",
      "gameID",
      "orgID",
      "gameHost"
    ]),
    ...mapGetters("auth", ["user", "clientID", "hasPreGame"]),
    isUserMuted() {
      return Boolean(this.user.muted)
    },
    menuActive: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    isIRLSession() {
      return this.$store.getters["auth/isIRLSession"]
    },
    isGamePage() {
      return this.$route.name === "game" || this.$route.name === "pickteams"
    },
    chatContext() {
      if (this.isGamePage) {
        return CHAT_CONTEXTS.TEAM
      } else {
        return CHAT_CONTEXTS.LOBBY
      }
    }
  },

  watch: {
    menuActive() {
      this.checkUnread()
    }
  },

  methods: {
    toggleUserMute() {
      UserSoundService.toggle(this.user)
    },
    async checkUnread() {
      if (!this.isTextChatOn && this.isGamePage) return

      const contextRef = ChatService.getRefByContext({
        context: this.chatContext,
        orgID: this.orgID,
        gameID: this.gameID,
        teamID: this.teamID,
        clientID: this.clientID
      })

      const timestamp = await ChatService.getLastActiveChatTimestamp({
        contextRef,
        userID: this.user.id
      })

      const messages =
        (
          await ChatService.unreadRef(contextRef, timestamp).once("value")
        ).val() || {}

      this.unreadMessages = Object.values(messages).length
    },
    async toggleCamera() {
      await this.$store.dispatch("twilio/tryToEnumerateDevices")
      const { deviceId } = this.$store.state.twilio.cameras.find(
        ({ deviceId }) => {
          return deviceId !== this.$store.state.twilio.camera
        }
      )
      await this.$store.dispatch("twilio/SET_CAMERA", deviceId)
      await this.$store.dispatch("twilio/UPDATE_LOCAL_VIDEO_TRACK")
    },
    async changeAV() {
      this.$emit("onChangeAV")
      this.menuActive = false
    }
  }
}
</script>

<style lang="scss">
.mobile-side-bar {
  position: fixed;
  height: 100%;
  z-index: 201;
  &__menu-item {
    &--active {
      i {
        color: $primary-accent-color !important;
      }
    }
  }

  &__circle {
    display: inline-flex;
    float: right;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $primary-accent-color;
  }
}
</style>
